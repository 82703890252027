<template>
  <v-container fluid>
    <v-card tile>

      <v-toolbar>
        <v-btn icon to="/admin/users" exact>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>New user</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-container v-if="loading">
          <v-row class="fill-height" align-content="center" justify="center">
            <v-col class="subtitle-1 text-center" cols="12">
              Loading data
            </v-col>
            <v-col cols="6">
              <v-progress-linear
                color="deep-purple accent-4"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-container>

        <v-form v-else @submit.prevent="saveUser">
          <v-text-field v-model="user.name" label="Name"></v-text-field>
          <v-text-field v-model="user.surname" label="Surname"></v-text-field>
          <v-text-field v-model="user.username" label="Username"></v-text-field>
          <v-text-field v-model="user.email" label="E-mail" type="email"></v-text-field>
          <v-text-field v-model="user.roomNumber" label="Room number"></v-text-field>
          <v-text-field v-model="user.dateOfBirth" label="Date of birth" type="date"></v-text-field>
          <v-text-field v-model="user.placeOfBirth" label="Place of birth"></v-text-field>
          <v-text-field v-model="user.permanentAddress" label="Permanent address"></v-text-field>
          <v-btn type="submit" color="success">Save</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      loading: true,
      user: Object()
    }
  },
  methods: {
    async saveUser () {
      // remove username field if it is blank
      if (this.user.username === '') {
        delete this.user.username
      }

      // create new user
      await this.$store.dispatch('user/createUser', this.user)

      // remove registration if user is based on registration instance
      if (this.$route.query.registration_id) {
        await this.$store.dispatch('registration/deleteRegistration', this.$route.query.registration_id)
      }

      // redirect to users view
      await this.$router.push('/admin/users')
    }
  },
  async mounted () {
    document.title = 'Edit User | Masarka Student Club'
    this.loading = true
    if (this.$route.query.registration_id) {
      this.user = await this.$store.dispatch('registration/fetchRegistrationById', this.$route.query.registration_id)
      delete this.user.id
    }
    this.loading = false
  }
}
</script>
